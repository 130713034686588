
$('.child').hide(); //Hide children by default
  $('.service').click(function(){
      event.preventDefault();
      $('.child').slideToggle();
      $('.service').toggleClass('active');
});

$('.mobile-drp-title').click(function(event) {
  $('.sub-drop').slideToggle('slow');
});

$('#main-mobile-section').hide();
$('#mobile div .fa-bars').click(function(){
  event.preventDefault();
  $('#main-mobile-section').slideToggle('slow');
});

$('#service-mobile-section').hide();
$('#mobile div .fa-desktop').click(function(){
  event.preventDefault();
  $('#service-mobile-section').slideToggle('slow');
});

$(".ticket-content").hide();
$(".show").click(function(){
  event.preventDefault();
  $('.ticket-content').toggle('slow');
  $('.show').toggleClass('showhide');
});
$('#main-mobile-section .dropbtn').click(function(event) {
  $(this).toggleClass('active').siblings().removeClass('active');
});